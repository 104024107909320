import { useTheme } from '@lokalise/styled';

/**
 * @deprecated use `@lokalise/styled` directly
 */
export {
    styled,
    styled as default,
    css,
    keyframes,
    ThemeProvider,
    createGlobalStyle,
    type CSSProperties,
    type CssDeclaration,
    useTheme,
} from '@lokalise/styled';

export const useCurrentMode = () => useTheme().mode;
